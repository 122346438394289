@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

.App{
  background-color: rgb(226, 226, 226);
  margin: 0;
  display : flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  gap : 10px;
}

.tab-rapper{
  width: fit-content;
  height: fit-content;
  transition: right 1000mm;
}

.result_rapper{
  width: fit-content;
  height: fit-content;
}

.mutip{
  font-size: xx-small;
}

.input_form{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

@media all and (max-width:965px){
  .input_form {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
  .App{
    flex-direction: column;
    gap : 20px
  }
}

@media all and (max-width:500px){
  .input_form {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    gap: 25px;
  }
  .App{
    flex-direction: column;
    gap : 20px
  }
}

.tableLoding-rappeer{
  display : flex; 
  justify-content : center; 
  align-content : center
}

.subtitle{
  color: darkgrey;
  font-size: 0.75rem;
  border-top: #9c9c9c 1px solid;
}

.subtitle-PLANB{
  font-family: 'Permanent Marker', cursive;
  font-size: 1.4rem;
  color: #ffffa6;
}

.maintitle{
  font-family: 'Permanent Marker', cursive;
  font-size: 2rem;
}
