html{
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  background-color:rgb(226, 226, 226);
  overflow-x: hidden;
}

body{
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
}




#root{
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: minmax(30px,auto) 1fr;
  gap: 10px;
}

